/**
 * @generated SignedSource<<834255c05d8e6ba48cc5998371d84db3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasSelfAssessment",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  },
  (v5/*: any*/),
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelfAssessmentInviteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SelfAssessment",
        "kind": "LinkedField",
        "name": "selfAssessment",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SelectParticipants_selfAssessment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelfAssessmentInviteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SelfAssessment",
        "kind": "LinkedField",
        "name": "selfAssessment",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organisation",
            "kind": "LinkedField",
            "name": "organisation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "sortBy",
                    "value": "name"
                  },
                  {
                    "kind": "Literal",
                    "name": "sortDirection",
                    "value": "asc"
                  },
                  {
                    "kind": "Literal",
                    "name": "statuses",
                    "value": [
                      "active",
                      "pending"
                    ]
                  }
                ],
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "users",
                "plural": true,
                "selections": (v7/*: any*/),
                "storageKey": "users(sortBy:\"name\",sortDirection:\"asc\",statuses:[\"active\",\"pending\"])"
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SaQuestionnaire",
            "kind": "LinkedField",
            "name": "saQuestionnaires",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1978c506890ebc179b591d01b8003353",
    "id": null,
    "metadata": {},
    "name": "SelfAssessmentInviteQuery",
    "operationKind": "query",
    "text": "query SelfAssessmentInviteQuery(\n  $id: ID!\n) {\n  currentOrganisation {\n    hasSelfAssessment\n    id\n  }\n  selfAssessment(id: $id) {\n    databaseId\n    endDate\n    name\n    createdBy {\n      name\n      id\n    }\n    ...SelectParticipants_selfAssessment\n    id\n  }\n}\n\nfragment SelectParticipants_selfAssessment on SelfAssessment {\n  databaseId\n  name\n  organisation {\n    users(sortBy: \"name\", sortDirection: \"asc\", statuses: [\"active\", \"pending\"]) {\n      databaseId\n      email\n      name\n      id\n    }\n    id\n  }\n  saQuestionnaires {\n    databaseId\n    email\n    name\n    id\n  }\n}\n"
  }
};
})();

node.hash = "a3e24c01b0efc8eeada3d354b741e7ca";

module.exports = node;
