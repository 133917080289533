/**
 * @generated SignedSource<<e49e23617773d7087ddd89f5073edc14>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uuid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answer",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isMandatory",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showLogo",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyLogo",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyLogo2x",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "questionType",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QuestionnaireQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaQuestionnaire",
        "kind": "LinkedField",
        "name": "questionnaire",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SaAnswer",
            "kind": "LinkedField",
            "name": "saAnswers",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SaQuestion",
                "kind": "LinkedField",
                "name": "saQuestion",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SaAnswerOption",
                "kind": "LinkedField",
                "name": "saAnswerOptions",
                "plural": true,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SelfAssessment",
            "kind": "LinkedField",
            "name": "selfAssessment",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organisation",
                "kind": "LinkedField",
                "name": "organisation",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SaSection",
                "kind": "LinkedField",
                "name": "saSections",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SaQuestion",
                    "kind": "LinkedField",
                    "name": "saQuestions",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v11/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "QuestionnaireQuestion_saQuestion"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ConfirmationModal_saQuestionnaire"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "QuestionnaireQuestion_saQuestionnaire"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QuestionnaireQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaQuestionnaire",
        "kind": "LinkedField",
        "name": "questionnaire",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SaAnswer",
            "kind": "LinkedField",
            "name": "saAnswers",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SaQuestion",
                "kind": "LinkedField",
                "name": "saQuestion",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SaAnswerOption",
                "kind": "LinkedField",
                "name": "saAnswerOptions",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v12/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "score",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "saQuestionId",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SelfAssessment",
            "kind": "LinkedField",
            "name": "selfAssessment",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organisation",
                "kind": "LinkedField",
                "name": "organisation",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SaSection",
                "kind": "LinkedField",
                "name": "saSections",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SaQuestion",
                    "kind": "LinkedField",
                    "name": "saQuestions",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v11/*: any*/),
                      (v9/*: any*/),
                      (v5/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SaAnswerOption",
                        "kind": "LinkedField",
                        "name": "saAnswerOptions",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "navigateToSectionId",
                            "storageKey": null
                          },
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uuid",
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9ad132958def4c771eb5062f9e8064cb",
    "id": null,
    "metadata": {},
    "name": "QuestionnaireQuery",
    "operationKind": "query",
    "text": "query QuestionnaireQuery(\n  $uuid: String!\n) {\n  questionnaire(uuid: $uuid) {\n    databaseId\n    status\n    saAnswers {\n      answer\n      saQuestion {\n        databaseId\n        isMandatory\n        id\n      }\n      saAnswerOptions {\n        databaseId\n        id\n      }\n      id\n    }\n    selfAssessment {\n      showLogo\n      organisation {\n        companyLogo\n        companyLogo2x\n        id\n      }\n      saSections {\n        databaseId\n        description\n        name\n        saQuestions {\n          databaseId\n          questionType\n          ...QuestionnaireQuestion_saQuestion\n          id\n        }\n        id\n      }\n      id\n    }\n    ...ConfirmationModal_saQuestionnaire\n    ...QuestionnaireQuestion_saQuestionnaire\n    id\n  }\n}\n\nfragment ConfirmationModal_saQuestionnaire on SaQuestionnaire {\n  uuid\n}\n\nfragment DragAnswerOptions_saQuestion on SaQuestion {\n  databaseId\n  name\n  saAnswerOptions {\n    databaseId\n    name\n    id\n  }\n}\n\nfragment QuestionnaireQuestion_saQuestion on SaQuestion {\n  databaseId\n  description\n  isMandatory\n  name\n  questionType\n  saAnswerOptions {\n    databaseId\n    description\n    name\n    navigateToSectionId\n    id\n  }\n  ...DragAnswerOptions_saQuestion\n}\n\nfragment QuestionnaireQuestion_saQuestionnaire on SaQuestionnaire {\n  saAnswers {\n    answer\n    id\n    saQuestionId\n    saAnswerOptions {\n      databaseId\n      description\n      name\n      score\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "b796716d9d7251d1f09d3af714a5e0da";

module.exports = node;
