/**
 * @generated SignedSource<<45edfa52f3f6dc1ccb658dfb6037c7bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "limit"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortBy"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortDirection"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "sortBy",
    "variableName": "sortBy"
  },
  {
    "kind": "Variable",
    "name": "sortDirection",
    "variableName": "sortDirection"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasSelfAssessment",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelfAssessmentsQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "SelfAssessmentList_query"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SelfAssessmentList_organisation"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelfAssessmentsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SelfAssessmentPagination",
        "kind": "LinkedField",
        "name": "selfAssessmentPagination",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SelfAssessment",
            "kind": "LinkedField",
            "name": "selfAssessments",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "databaseId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organisation",
                "kind": "LinkedField",
                "name": "organisation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "companyName",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SaQuestionnaire",
                "kind": "LinkedField",
                "name": "saQuestionnaires",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SelfAssessmentTemplate",
                "kind": "LinkedField",
                "name": "selfAssessmentTemplate",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasChildOrganisations",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ce0a2c28523545cb04555c81813870c5",
    "id": null,
    "metadata": {},
    "name": "SelfAssessmentsQuery",
    "operationKind": "query",
    "text": "query SelfAssessmentsQuery(\n  $limit: Int!\n  $page: Int!\n  $sortBy: String!\n  $sortDirection: String!\n) {\n  ...SelfAssessmentList_query_35Jp96\n  currentOrganisation {\n    hasSelfAssessment\n    ...SelfAssessmentList_organisation\n    id\n  }\n}\n\nfragment SelfAssessmentList_organisation on Organisation {\n  ...SelfAssessment_organisation\n  hasChildOrganisations\n}\n\nfragment SelfAssessmentList_query_35Jp96 on Query {\n  selfAssessmentPagination(limit: $limit, page: $page, sortBy: $sortBy, sortDirection: $sortDirection) {\n    selfAssessments {\n      id\n      ...SelfAssessment_selfAssessment\n    }\n    totalCount\n  }\n}\n\nfragment SelfAssessment_organisation on Organisation {\n  hasChildOrganisations\n}\n\nfragment SelfAssessment_selfAssessment on SelfAssessment {\n  databaseId\n  endDate\n  name\n  organisation {\n    companyName\n    id\n  }\n  saQuestionnaires {\n    status\n    id\n  }\n  selfAssessmentTemplate {\n    name\n    id\n  }\n}\n"
  }
};
})();

node.hash = "300e087735451fce691b3a2c63086be9";

module.exports = node;
