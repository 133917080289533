/**
 * @generated SignedSource<<789c937a530a28e4bbe113d09a874449>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasSelfAssessment",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "progress",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "score",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SaQuestionnaireQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SaQuestionnaire",
        "kind": "LinkedField",
        "name": "saQuestionnaire",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SaQuestionnaireResults_saQuestionnaire"
          },
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SaAnswer",
            "kind": "LinkedField",
            "name": "saAnswers",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SaQuestion",
                "kind": "LinkedField",
                "name": "saQuestion",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SaAnswerOption",
                    "kind": "LinkedField",
                    "name": "saAnswerOptions",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SelfAssessment",
            "kind": "LinkedField",
            "name": "selfAssessment",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SaQuestionnaireQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SaQuestionnaire",
        "kind": "LinkedField",
        "name": "saQuestionnaire",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SaAnswer",
            "kind": "LinkedField",
            "name": "saAnswers",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SaQuestion",
                "kind": "LinkedField",
                "name": "saQuestion",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SaAnswerOption",
                    "kind": "LinkedField",
                    "name": "saAnswerOptions",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SelfAssessment",
            "kind": "LinkedField",
            "name": "selfAssessment",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              (v4/*: any*/),
              (v9/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b865ae1803733461d433ea1f4f070495",
    "id": null,
    "metadata": {},
    "name": "SaQuestionnaireQuery",
    "operationKind": "query",
    "text": "query SaQuestionnaireQuery(\n  $id: ID!\n) {\n  currentOrganisation {\n    hasSelfAssessment\n    id\n  }\n  saQuestionnaire(id: $id) {\n    ...SaQuestionnaireResults_saQuestionnaire\n    __typename\n    id\n    progress\n    saAnswers {\n      id\n      saQuestion {\n        position\n        saAnswerOptions {\n          name\n          score\n          id\n        }\n        id\n      }\n    }\n    selfAssessment {\n      databaseId\n      name\n      id\n    }\n  }\n}\n\nfragment SaQuestionnaireResults_saQuestionnaire on SaQuestionnaire {\n  saAnswers {\n    id\n    saQuestion {\n      position\n      id\n    }\n  }\n  selfAssessment {\n    description\n    id\n  }\n}\n"
  }
};
})();

node.hash = "5fc409d68707aec6f3b0690405b99e6d";

module.exports = node;
