/**
 * @generated SignedSource<<4c7ae57d276485f5d9c1b53b97de0db0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "SaSection",
  "kind": "LinkedField",
  "name": "saSections",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v5/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SaQuestion",
      "kind": "LinkedField",
      "name": "saQuestions",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v5/*: any*/),
        (v4/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isMandatory",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "questionType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SaAnswerOption",
          "kind": "LinkedField",
          "name": "saAnswerOptions",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            (v5/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "navigateToSectionId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "score",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelfAssessmentQuestionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SelfAssessment",
        "kind": "LinkedField",
        "name": "selfAssessment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SaQuestionnaire",
            "kind": "LinkedField",
            "name": "saQuestionnaires",
            "plural": true,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "QuestionnaireSection_selfAssessment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SaveAsTemplateModal_selfAssessment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelfAssessmentQuestionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SelfAssessment",
        "kind": "LinkedField",
        "name": "selfAssessment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SaQuestionnaire",
            "kind": "LinkedField",
            "name": "saQuestionnaires",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isQuiz",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organisationId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SelfAssessmentTemplate",
            "kind": "LinkedField",
            "name": "selfAssessmentTemplate",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cf9d965df76a5c8e66cc2fceef367ea9",
    "id": null,
    "metadata": {},
    "name": "SelfAssessmentQuestionsQuery",
    "operationKind": "query",
    "text": "query SelfAssessmentQuestionsQuery(\n  $id: ID!\n) {\n  selfAssessment(id: $id) {\n    databaseId\n    id\n    name\n    saQuestionnaires {\n      databaseId\n      id\n    }\n    saSections {\n      databaseId\n      description\n      id\n      name\n      saQuestions {\n        databaseId\n        description\n        name\n        id\n        isMandatory\n        questionType\n        saAnswerOptions {\n          databaseId\n          description\n          id\n          name\n          navigateToSectionId\n          score\n        }\n      }\n    }\n    ...QuestionnaireSection_selfAssessment\n    ...SaveAsTemplateModal_selfAssessment\n  }\n}\n\nfragment QuestionnaireQuestion_selfAssessment on SelfAssessment {\n  isQuiz\n}\n\nfragment QuestionnaireSection_selfAssessment on SelfAssessment {\n  databaseId\n  ...QuestionnaireQuestion_selfAssessment\n}\n\nfragment SaveAsTemplateModal_selfAssessment on SelfAssessment {\n  databaseId\n  organisationId\n  selfAssessmentTemplate {\n    description\n    id\n    name\n  }\n}\n"
  }
};
})();

node.hash = "8a118e39ccd967365e13ed67ead01521";

module.exports = node;
