/**
 * @generated SignedSource<<1c5476f758e6cc55d5d607c99ef05679>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "limit"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organisationIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortBy"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortDirection"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  },
  {
    "kind": "Variable",
    "name": "sortBy",
    "variableName": "sortBy"
  },
  {
    "kind": "Variable",
    "name": "sortDirection",
    "variableName": "sortDirection"
  }
],
v2 = {
  "kind": "Variable",
  "name": "organisationIds",
  "variableName": "organisationIds"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "organisationId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasSelfAssessment",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewSelfAssessmentQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "SaTemplateSelector_query"
      },
      {
        "args": [
          (v2/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "SelfAssessmentForm_query"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Member",
        "kind": "LinkedField",
        "name": "currentMember",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewSelfAssessmentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SelfAssessmentTemplatePagination",
        "kind": "LinkedField",
        "name": "selfAssessmentTemplatePagination",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SelfAssessmentTemplate",
            "kind": "LinkedField",
            "name": "selfAssessmentTemplates",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisations",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "companyName",
            "storageKey": null
          },
          (v7/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          (v2/*: any*/),
          {
            "kind": "Literal",
            "name": "roles",
            "value": [
              "admin",
              "editor"
            ]
          },
          {
            "kind": "Literal",
            "name": "sortBy",
            "value": "name"
          },
          {
            "kind": "Literal",
            "name": "sortDirection",
            "value": "asc"
          },
          {
            "kind": "Literal",
            "name": "statuses",
            "value": [
              "active"
            ]
          }
        ],
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "users",
        "plural": true,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Member",
        "kind": "LinkedField",
        "name": "currentMember",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "47ef4a3b3afd8c5cd679cf6304d49ee3",
    "id": null,
    "metadata": {},
    "name": "NewSelfAssessmentQuery",
    "operationKind": "query",
    "text": "query NewSelfAssessmentQuery(\n  $limit: Int!\n  $organisationIds: [Int!]\n  $page: Int!\n  $query: String\n  $sortBy: String!\n  $sortDirection: String!\n) {\n  ...SaTemplateSelector_query_1JgTUc\n  ...SelfAssessmentForm_query_2ND0HR\n  currentMember {\n    organisationId\n    userId\n    id\n  }\n  currentOrganisation {\n    hasSelfAssessment\n    id\n  }\n}\n\nfragment SaTemplateSelector_query_1JgTUc on Query {\n  selfAssessmentTemplatePagination(limit: $limit, page: $page, query: $query, sortBy: $sortBy, sortDirection: $sortDirection) {\n    selfAssessmentTemplates {\n      id\n      ...SelfAssessmentTemplateSelector_selfAssessmentTemplate\n    }\n    totalCount\n  }\n}\n\nfragment SelfAssessmentForm_query_2ND0HR on Query {\n  organisations {\n    companyName\n    databaseId\n    id\n  }\n  users(organisationIds: $organisationIds, roles: [\"admin\", \"editor\"], sortBy: \"name\", sortDirection: \"asc\", statuses: [\"active\"]) {\n    databaseId\n    name\n    id\n  }\n}\n\nfragment SelfAssessmentTemplateSelector_selfAssessmentTemplate on SelfAssessmentTemplate {\n  databaseId\n  description\n  name\n}\n"
  }
};
})();

node.hash = "339017406e745e49687219f3d918f062";

module.exports = node;
